<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div fill-height class="mt-6 ml-8 mr-6">
    <div>
      <v-icon
        id="iiotDeployDetailsBackButton"
        data-cy="iiotDeployDetailsBackButton"
        large
        class="ml-n5 mb-3"
        @click="goToDeployLogPage()"
        >mdi-chevron-left
      </v-icon>
      <span v-if="getCampaign" id="iiotDeployDetailsTitle" class="deployment-title">
        {{ $t('deployDetails.detailOfDep') }} {{ getCampaign.operation_name }}
      </span>
    </div>
    <the-nerve-table
      :id="'iiotDeployDetailsTable'"
      :columns="columns"
      :params="params"
      :empty-state="$t('deployDetails.list.emptyList')"
      :is-action-menu-enabled="false"
      :have-access="canAccess('UI_DEPLOY:LOG_VIEW')"
      :is-row-clickable="false"
      store-module="deploy-details"
      @params-changed="updateParams"
    >
      <template #additional-actions>
        <v-row v-resize="onResize">
          <v-col
            cols="4"
            lg="2"
            md="2"
            sm="3"
            class="mt-n1 d-flex justify-start align-center"
            :class="{ 'pb-0': isMarginVisible }"
          >
            <v-icon id="iiotDeployDetailsFilter" min-width="40px"> mdi-filter-outline </v-icon>
            <v-checkbox
              id="iiotDeployDetailsFinishedCheckbox"
              v-model="params.selectedSuccessful"
              min-width="40px"
              :label="$t('deployDetails.statusMessages.successful')"
              @change="filterBy()"
            />
          </v-col>
          <v-col
            cols="5"
            lg="3"
            md="3"
            sm="4"
            class="mt-n1 d-flex justify-center align-center"
            :class="{ 'pb-0': isMarginVisible }"
          >
            <v-checkbox
              id="iiotDeployDetailsInProgressCheckbox"
              v-model="params.selectedInProgress"
              min-width="40px"
              :label="$t('deployDetails.statusMessages.inProgress')"
              @change="filterBy()"
            />
          </v-col>
          <v-col
            cols="3"
            lg="2"
            md="2"
            class="mt-n1 d-flex justify-center align-center"
            :class="{ 'pb-0': isMarginVisible }"
          >
            <v-checkbox
              id="iiotDeployDetailsFailedCheckbox"
              v-model="params.selectedFailed"
              min-width="40px"
              :label="$t('deployDetails.statusMessages.failed')"
              @change="filterBy()"
            />
          </v-col>
          <v-col
            id="iiotNodeUpdateDetailsListCancelledCheckbox"
            cols="4"
            lg="2"
            md="2"
            sm="3"
            :class="{
              'mt-n1 d-flex justify-end align-center': !isMarginVisible,
              'pt-0 d-flex justify-start align-center': isMarginVisible,
            }"
          >
            <v-checkbox
              id="iiotDeployDetailsCanceledCheckbox"
              v-model="params.selectedCanceled"
              min-width="40px"
              :label="$t('deployDetails.statusMessages.canceled')"
              @change="filterBy()"
            />
          </v-col>
          <v-col
            class="mt-n2 d-flex justify-center align-center"
            :class="{ 'pt-0': isMarginVisible }"
            lg="1"
            md="1"
            cols="2"
          >
            <deploy-details-table-actions-reset :content="content" />
          </v-col>
        </v-row>
      </template>
      <template #additional-info>
        <div class="mt-8">
          <v-row>
            <v-col lg="2" md="3" sm="5" xs="5">
              <div @mouseover="isTooltip($event)">
                <v-card
                  id="iiotDeployDetailsWorkloadName"
                  min-height="100"
                  max-height="100"
                  min-width="150"
                  color="#f0f3f5"
                >
                  <v-card-title id="iiotDeployDetailsWorkloadNameTitle" class="card-title">
                    {{ $t('deployDetails.workloadName') }}:
                  </v-card-title>
                  <v-card-text
                    :id="`iiotDeployDetailsWorkloadNameTextLink-${workloadExists}`"
                    :class="{ 'cursor-pointer clickableLink': workloadExists && canAccess('UI_WORKLOAD:VIEW') }"
                    @click="rerouteUser('workload')"
                  >
                    <v-tooltip v-if="tooltip" top>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" class="text-truncate" v-on="on">
                          {{ workloadName }}
                        </div>
                      </template>
                      <span class="p0-12 mt-16">{{ workloadName }}</span>
                    </v-tooltip>
                    <div v-else class="text-truncate">
                      {{ workloadName }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col lg="2" md="3" sm="5">
              <div @mouseover="isTooltip($event)">
                <v-card
                  id="iiotDeployDetailsWorkloadVersion"
                  min-height="100"
                  max-height="100"
                  min-width="150"
                  color="#f0f3f5"
                >
                  <v-card-title id="iiotDeployDetailsWorkloadVersionTitle" class="card-title">
                    {{ $t('deployDetails.workloadVersion') }}:
                  </v-card-title>

                  <v-card-text
                    :id="`iiotDeployDetailsWorkloadVersionTextLink-${versionExists}`"
                    :class="{ 'cursor-pointer clickableLink': versionExists && canAccess('UI_WORKLOAD:VERSION_VIEW') }"
                    @click="rerouteUser('version')"
                  >
                    <v-tooltip v-if="tooltip" top>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" class="text-truncate" v-on="on">
                          {{ workloadVersion }}
                        </div>
                      </template>
                      <span class="p0-12 mt-16">{{ workloadVersion }}</span>
                    </v-tooltip>
                    <div v-else class="text-truncate">
                      {{ workloadVersion }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col lg="2" md="3" sm="5">
              <v-card
                id="iiotDeployDetailsOperationStartTime"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title id="iiotDeployDetailsOperationStartTimeTitle" class="card-title">
                  {{ $t('deployDetails.operationStartTime') }}:
                </v-card-title>
                <v-card-text v-if="getCampaign">
                  {{ getCampaign.timeOfOperationStart }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="2" md="3" sm="5">
              <v-card
                id="iiotDeployDetailsOperationFinishTime"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title id="iiotDeployDetailsOperationFinishTimeTitle" class="card-title">
                  {{ $t('deployDetails.operationFinishTime') }}:
                </v-card-title>
                <v-card-text v-if="getCampaign">
                  {{ getCampaign.timeOfOperationFinish }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col lg="2" md="3" sm="5">
              <div @mouseover="isTooltip($event)">
                <v-card
                  id="iiotDeployDetailsReleasedName"
                  min-height="100"
                  max-height="100"
                  min-width="150"
                  color="#f0f3f5"
                >
                  <v-card-title id="iiotDeployDetailsReleasedNameTitle" class="card-title">
                    {{ $t('deployDetails.releaseName') }}:
                  </v-card-title>

                  <v-card-text>
                    <v-tooltip v-if="tooltip" top>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" class="text-truncate" v-on="on">
                          {{ releaseName }}
                        </div>
                      </template>
                      <span class="p0-12 mt-16">{{ releaseName }}</span>
                    </v-tooltip>
                    <div v-else class="text-truncate">
                      {{ releaseName }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col lg="2" md="3" sm="5">
              <v-card id="iiotDeployDetailsType" min-height="100" max-height="100" min-width="150" color="#f0f3f5">
                <v-card-title id="iiotDeployDetailsTypeTitle" class="card-title">
                  {{ $t('deployDetails.type') }}:
                </v-card-title>
                <v-card-text v-if="getCampaign">
                  {{ getCampaign.contentSubtype }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="2" md="3" sm="5">
              <v-card id="iiotDeployDetailsStatus" min-height="100" max-height="100" min-width="150" color="#f0f3f5">
                <v-card-title id="iiotDeployDetailsStatusTitle" class="card-title">
                  {{ $t('deployDetails.status') }}:
                </v-card-title>
                <v-card-text v-if="getCampaign">
                  {{ getCampaign.status }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="2" md="3" sm="5">
              <v-card id="iiotDeployDetailsProgress" min-height="100" max-height="100" min-width="150" color="#f0f3f5">
                <v-card-title id="iiotDeployDetailsProgressTitle" class="card-title">
                  {{ $t('deployDetails.progress') }}:
                </v-card-title>
                <v-card-text v-if="getCampaign.campaignOptions">
                  {{ getCampaign.campaignOptions.progress }} %
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </template>
    </the-nerve-table>
  </div>
</template>

<script>
import { TheNerveTable, ProgressLinearWithStatus } from 'nerve-ui-components';
import DeployDetailsTableActionsReset from '@/components/deploy/deploy-helpers/DeployDetailsTableActionsReset.vue';
import mqtt from '@/plugins/mqtt';
import ComposeWorkloadApiService from '@/services/api/compose-workload.api-service';
import { WorkloadsApiService } from '@/services/api';

export default {
  components: {
    TheNerveTable,
    DeployDetailsTableActionsReset,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    search: {
      type: String,
      default: '',
    },
    isFinished: {
      type: String,
      default: '',
    },
    isCanceled: {
      type: String,
      default: '',
    },
    inProgress: {
      type: String,
      default: '',
    },
    isFailed: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      params: {
        id: '',
      },
      workloadName: '',
      workloadVersion: '',
      releaseName: '',
      content: {},
      isMarginVisible: false,
      workloadExists: false,
      versionExists: false,
      workloadId: '',
      versionId: '',
      workloadType: '',
      tooltip: true,
      isLinkReady: false,
    };
  },
  computed: {
    getCampaign() {
      return this.$store.getters['deploy-details/campaign'];
    },
    getCampaignAction() {
      return this.$store.getters['deploy-details/list'][0];
    },
    columns() {
      return [
        {
          text: '',
          value: 'action',
          sortable: false,
          component: {
            sfc: () => import('@/components/deploy/deploy-helpers/DeployIcon.vue'),
            props: {
              icon: this.getCampaignAction,
            },
          },
        },
        {
          text: this.$t('deployDetails.list.headerDeviceName'),
          value: 'deviceName',
          sortable: false,
          component: {
            sfc: () => import('@/components/TableContentAsLink.vue'),
            props: {
              linkKey: 'deviceName',
              isLinkAllowed: this.canAccess('UI_NODE_TREE:NODE_DETAILS'),
              queryParams: this.params,
            },
          },
        },
        {
          text: this.$t('deployDetails.list.headerSerialNum'),
          sortable: false,
          component: {
            sfc: () => import('@/components/TableContentAsLink.vue'),
            props: {
              linkKey: 'device',
              isLinkAllowed: this.canAccess('UI_NODE_TREE:NODE_DETAILS'),
              queryParams: this.params,
            },
          },
        },
        {
          text: this.$t('deployDetails.list.headerProgress'),
          value: 'progress',
          sortable: false,
          component: {
            sfc: ProgressLinearWithStatus,
            props: {
              page: 'deployLogDetails',
            },
          },
        },
        {
          text: this.$t('deployDetails.list.headerCounter'),
          value: 'retryCounter',
          sortable: false,
        },
        {
          text: this.$t('deployDetails.list.headerStarted'),
          value: 'started',
          sortable: false,
        },
        {
          text: this.$t('deployDetails.list.headerFinished'),
          value: 'finished',
          sortable: false,
        },
        {
          text: this.canAccess('UI_DEPLOY:LOG_RESET') ? this.$t('defaultTable.action') : '',
          value: '',
          width: '0%',
          align: 'center',
          sortable: false,
          component: {
            sfc: () => import('@/components/deploy/deploy-helpers/DeployDetailsTableActionsResetAndFeedback.vue'),
            props: {
              iconsAction: [
                'Retry',
                'DisabledRetry',
                'MarkTaskAsSuccess',
                'DisabledMarkTaskAsSuccess',
                'MarkTaskAsFail',
                'DisabledMarkTaskAsFail',
              ],
            },
          },
        },
      ];
    },
  },
  async created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      selectedSuccessful: this.isFinished !== 'undefined' ? this.isFinished === 'true' : true,
      selectedInProgress: this.inProgress !== 'undefined' ? this.inProgress === 'true' : true,
      selectedFailed: this.isFailed !== 'undefined' ? this.isFailed === 'true' : true,
      selectedCanceled: this.isCanceled !== 'undefined' ? this.isCanceled === 'true' : true,
    };

    await mqtt.subscribeTo('cloud');

    await this.$store.dispatch('deploy-details/get_bulk_operation', window.location.pathname.split('/')[3]);
    await this.$store.dispatch('node-update-log/setType', { type: 'workload' });

    this.content = this.$store.getters['deploy-details/bulkOperation'];
    await this.$store.dispatch('deploy-details/getLog', this.content);

    // eslint-disable-next-line prefer-destructuring
    this.params.id = window.location.pathname.split('/')[3];

    await this.$store.dispatch('deploy-details/fetch', this.params);
    this.$store.dispatch('node-tree/get_full_tree');

    this.content_content = this.$store.getters['deploy-details/content'];
    this.workloadName = this.content_content.content_params[0].params.workloadName;
    this.workloadVersion = this.content_content.content_params[0].params.version.name;
    this.releaseName = this.content_content.content_params[0].params.version.releaseName;

    this.workloadId = this.content_content.content_params[0].params.workloadId;
    this.versionId = this.content_content.content_params[0].params.version.id;
    this.workloadType = this.content_content.content_subtype;
    await this.checkIfWorkloadAndVersionAreAvailable();
    this.$store.dispatch('workloads/set_selected_deployed_workload_version_timestamp', { dateString: null });
  },
  beforeDestroy() {
    this.$store.dispatch('deploy-details/clear_deploy_details_list_and_count');
    this.$mqtt.unsubscribeFrom('cloud');
  },
  methods: {
    updateParams(parameters) {
      this.params = {
        ...parameters,
        // eslint-disable-next-line no-underscore-dangle
        id: this.content._id,
        selectedSuccessful: this.params.selectedSuccessful,
        selectedInProgress: this.params.selectedInProgress,
        selectedFailed: this.params.selectedFailed,
        selectedCanceled: this.params.selectedCanceled,
      };
      this.$router
        .push({
          name: 'Deploy Details',
          query: {
            page: parameters.page,
            itemsPerPage: parameters.itemsPerPage,
            search: parameters.search || undefined,
            isFinished: parameters.selectedSuccessful,
            isCanceled: parameters.selectedCanceled,
            inProgress: parameters.selectedInProgress,
            isFailed: parameters.selectedFailed,
          },
        })
        .catch(() => {});
    },
    async filterBy() {
      this.params.page = 1;
      await this.$store.dispatch('deploy-details/fetch', this.params);
    },
    goToDeployLogPage() {
      this.$store.dispatch('deploy-details/clear_deploy_details_list_and_count');
      this.$router.push({ name: 'Deploy Log List', query: this.$store.getters['labels/getQuery'] });
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 1264;
    },
    async checkIfWorkloadAndVersionAreAvailable() {
      try {
        if (!this.canAccess('UI_WORKLOAD:VIEW')) {
          throw Error();
        }
        await ComposeWorkloadApiService.getComposeWorkload({
          id: this.workloadId,
          ignoreInterceptor: true,
        });
      } catch {
        this.workloadExists = false;
        this.versionExists = false;
        return;
      }
      this.workloadExists = true;
      if (!this.canAccess('UI_WORKLOAD:VERSION_VIEW')) {
        return;
      }
      await this.checkIfVersionExists(true);
    },
    async checkIfVersionExists(ignoreInterceptor) {
      if (this.workloadType === 'docker-compose') {
        try {
          await ComposeWorkloadApiService.fetchComposeWorkloadVersionById({
            wlId: this.workloadId,
            verId: this.versionId,
            ignoreInterceptor,
          });
          this.versionExists = true;
        } catch {
          this.versionExists = false;
        }
      } else {
        try {
          const wlWithVersions = await WorkloadsApiService.getWorkloadVersions({
            id: this.workloadId,
            ignoreInterceptor,
          });
          const version =
            wlWithVersions && wlWithVersions.versions
              ? wlWithVersions.versions.find((v) => v._id === this.versionId)
              : {};
          if (!version) {
            if (!ignoreInterceptor) {
              this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
                text: 'errorMessages.000703',
                color: 'red',
                showClose: true,
              });
            }
            this.versionExists = false;
            return;
          }
          this.versionExists = true;
        } catch {
          this.versionExists = false;
        }
      }
    },
    async rerouteUser(destination) {
      // eslint-disable-next-line default-case
      switch (destination) {
        case 'workload':
          if (!this.workloadExists) {
            return;
          }
          try {
            await ComposeWorkloadApiService.getComposeWorkload({
              id: this.workloadId,
            });
            if (this.workloadExists) {
              // Reroute user to workload page
              this.$router.push({
                name: 'Add edit workload',
                params: {
                  id: this.workloadId,
                  type: this.workloadType,
                },
              });
            }
          } catch {
            this.workloadExists = false;
            this.versionExists = false;
          }
          break;
        case 'version':
          if (!this.versionExists || !this.workloadExists) {
            return;
          }
          try {
            await this.checkIfVersionExists();
            await this.$store.dispatch('workloads/set_selected_deployed_workload_version_timestamp', {
              dateString: this.getCampaign.timeOfOperationFinish,
            });
            if (this.versionExists) {
              // Reroute user to Basic tab on version definition page
              this.$router.push({
                name: 'Add edit workload version',
                params: {
                  workloadId: this.workloadId,
                  versionId: this.versionId,
                  type: this.workloadType,
                },
                query: { tab: 0 },
              });
            }
          } catch {
            this.versionExists = false;
          }
      }
    },
    // tooltip will be shown only in the case when text is too big and when contains 3 dots
    isTooltip(event) {
      this.tooltip = event.target.scrollWidth > event.target.offsetWidth;
    },
  },
};
</script>
<style scoped>
.deployment-title {
  font-size: 24px;
  font-weight: 600;
}
.card-title {
  font-size: 14px;
  font-weight: bolder;
}
.clickableLink {
  color: var(--v-primary-base) !important;
}
.clickableLink:hover {
  text-decoration: underline !important;
}
</style>
