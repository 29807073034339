<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-menu bottom left>
    <template #activator="{ on, attrs }">
      <v-btn
        :id="customId !== '' ? 'iiotListActionMenu_' + customId + '_' + index : 'iiotListActionMenu_' + index"
        v-bind="attrs"
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, i) in actionMenuItems"
        :id="
          customId !== ''
            ? 'iiotListActionMenuItem_' + customId + '_' + index + '_' + i
            : 'iiotListActionMenuItem_' + index + '_' + i
        "
        :key="i"
        :disabled="!item.isAllowed"
        @click="item.action(tableItem)"
      >
        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    actionMenuItems: {
      type: Array,
      default: () => [],
    },
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: '',
    },
    customId: {
      type: String,
      default: '',
    },
  },
};
</script>
