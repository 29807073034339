<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <deploy-details
    :page="page"
    :items-per-page="itemsPerPage"
    :search="search"
    :is-finished="String(isFinished)"
    :is-canceled="String(isCanceled)"
    :in-progress="String(inProgress)"
    :is-failed="String(isFailed)"
  />
</template>
<script>
import DeployDetails from '@/components/deploy/DeployDetails.vue';

export default {
  components: {
    DeployDetails,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    search: {
      type: String,
      default: '',
    },
    isFinished: {
      type: String,
      default: '',
    },
    isCanceled: {
      type: String,
      default: '',
    },
    inProgress: {
      type: String,
      default: '',
    },
    isFailed: {
      type: String,
      default: '',
    },
  },
};
</script>
